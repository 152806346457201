
import { defineComponent, onMounted, ref, watch } from "vue";
import RequestStepper from "@/components/common/RequestStepper.vue";
import { UserRequest } from "@/interfaces";
import { useStore } from "vuex";
import RequestService from "@/services/RequestService";

export default defineComponent({
  name: "new-request",
  components: {
    RequestStepper,
  },
  setup() {
    const isMounted = ref(false);
    const request = ref<UserRequest>();
    const store = useStore();
    const reqId = ref(null);

    const reqToggle = store.getters.requestToggle;

    request.value = {
      requestId: null,
      isLocked: null,
      currentStep: 0,
      allowedStep: 1,
      form: {},
      history: null,
      user: store.getters.getUser.user,
      email: null,
      status: null,
    };

    const getId = (id: any) => {
      reqId.value = id;
    };

    const getSetRequest = async (id: any) => {
      request.value = await RequestService.getRequest(String(id));
    };

    watch(reqToggle, () => {
      getSetRequest(reqId.value);
    });

    onMounted(() => {
      isMounted.value = true;
    });

    return {
      isMounted,
      request,
      getSetRequest,
      getId,
    };
  },
});
